<!--钥匙完善率-->
<template>
  <div class="rate_page">
    <!--rate_page_list start-->
    <div class="rate_page_list">
      <div class="personage_head">
        <!-- <div class="personage_head_top">
          <div class="personage_head_txt">房源类型:</div>
          <ul class="personage_head_ul">
            <li @click="changeType(index)" :class="{active:item.type == typeList[type].type}" v-for="(item,index) in typeList" :key="item.type">{{ item.name }}</li>
          </ul>
        </div> -->
        <div class="personage_head_top">
          <div class="personage_head_txt">类型:</div>
          <ul class="personage_head_ul">
            <li @click="changeHouseType(index)" :class="{active:item.value === house_type_list[house_type].value}" v-for="(item,index) in house_type_list" :key='index'>{{ item.label }}</li>
          </ul>
        </div>
      </div>
      <ul class="personage_ul">
        <li v-for="(item, index) in list" :key="index">
          <div class="personage_ul_img m-r-20">
            <img
              :src="item.fang_fengmian_image"
              alt=""
            />
            <div class="personage_img_marking">{{ item.fang_level }}</div>
          </div>
          <div class="personage_ul_main f-1">
            <div class="personage_ul_name">{{ item.house_name }}</div>
            <div class="personage_ul_title">
              {{ item.fang_xing || '无' }} | {{ item.fang_mianji }}m² | {{ item.dian_number }}次 | 2天 | {{ item.name }}
            </div>
            <div class="personage_ul_tags flex-wrap">
              <span class="tag" v-for="(em) in item.tag" :key="em">{{ em }}</span>
            </div>
            <div class="personage_ul_price flex-between">
              <div class="flex">
                <div class="personage_ul_price_left m-r-20">
                  <span>{{ item.zong_price }}/万</span>
                  <img src="../../assets/image/personage/up2.png" alt="" />
                </div>
                <div class="personage_ul_price_right">
                  <span>{{ item.can_price }}元/m²</span>
                  <img src="../../assets/image/personage/up1.png" alt="" />
                </div>
              </div>

              <div class="personage_ul-bot">
                <button class="rate_page_btn m-r-20" @click="cancel(item)">取消锁定</button>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="paging" v-if="list.length != 0">
        <el-pagination
          background
          layout="prev, pager, next, jumper, ->, total"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!--rate_page_list end-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: 1, //页数
      total: 0, //条数
      list: [], //列表
      type:1,//房源类型
      typeList:[
        {
          name:'出售',
          type:1
        },
        {
          name:'出租',
          type:2
        }
      ],
      house_type:0,//类型
      house_type_list:[
        // {
        //   name:'新房',
        //   type:1
        // },
        {
          name:'住宅',
          type:2
        },
        {
          name:'公寓',
          type:3
        },
        {
          name:'写字楼',
          type:4
        },
        {
          name:'商铺',
          type:5
        },
        {
          name:'别墅',
          type:6
        }
      ],
    };
  },
  methods:{
    //取消锁定
    cancel (item) {
      this.$axios.cancelLog({id:item.id}).then(res=>{
        this.page = 1
        this.getHouseLockLog()
        this.$message({
          message: res.msg,
          type: 'success'
        });
      })
    },
    //切换房源类型
    changeHouseType (index) {
      this.page = 1
      this.house_type = index
      this.getHouseLockLog()
    },
    //切换类型
    changeType (index) {
      this.page = 1
      this.type = index
      this.getHouseLockLog()
    },
    //获取记录
    getHouseLockLog () {
      this.$axios.houseLockLog({
        page:this.page,
        type:this.typeList[this.type].type,
        house_type:this.house_type_list[this.house_type].value
      }).then(res=>{
        res.data.data.forEach(element => {
          element.tag = element.fang_biaoqian.split(',')
        });
        this.total = res.data.total
        this.list = res.data.data
      })
    }
  },
  created () {
    this.house_type_list = this.getZuHousingTypeList().filter(option => option.value !== -1)
    this.getHouseLockLog()
  }
};
</script>

<style lang="less" scoped>
.personage_head {
  padding: 30px 0;
  border-bottom: 1px solid #ebebeb;
  .personage_head_top {
    display: flex;
    &:last-child {
      margin-top: 15px;
    }
    .personage_head_txt {
      margin-right: 30px;
      width: 70px;
      color: #333333;
      font-size: 16px;
      font-weight: bold;
    }
    .personage_head_ul {
      display: flex;
      flex-wrap: wrap;
      li {
        cursor: pointer;
        color: #333333;
        font-size: 16px;
        margin-right: 40px;
        &:last-child {
          margin-right: 0;
        }
      }
      .active {
        color: #3273f6;
        font-weight: bold;
      }
    }
  }
}
.rate_page_head {
  background: #fff;
  padding: 0 40px;
  align-items: center;
  margin-bottom: 10px;
  height: 64px;
  .rate_nav {
    .li {
      font-size: 18px;
      color: #666;
      margin-right: 55px;
      cursor: pointer;
      position: relative;
      line-height: 64px;
    }
    .active {
      color: #3273f6;
    }
    .active:after {
      width: 24px;
      height: 2px;
      background: #3273f6;
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: -12px;
      display: block;
      content: "";
    }
  }
  .rate_total {
    font-size: 18px;
  }
}
.rate_page_list {
  background: #fff;
  padding: 0 50px;
}
.personage_ul_tags {
  display: flex;
  margin: 0 -5px;
  .tag {
    background: #f5f5f5;
    height: 28px;
    border-radius: 3px;
    padding: 0 20px;
    margin: 5px;
    font-size: 14px;
    color: #999;
    line-height: 28px;
  }
}
.rate_page_btn {
  width: 112px;
  height: 40px;
  border: 1px solid #3273f6;
  border-radius: 4px;
  color: #3273f6;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  background: #fff;
  outline: none;
  cursor: pointer;
}
.rate_page_btn.orange {
  background: #ffa740;
  border: 1px solid #ffa740;
  color: #fff;
}
.rate_page_btn.blue {
  background: #3273f6;
  border: 1px solid #3273f6;
  color: #fff;
}
.personage_ul {
  li {
    padding: 40px 0;
    display: flex;
    border-bottom: 1px solid #ebebeb;
    .personage_ul_img {
      img {
        width: 220px;
        height: 165px;
        border-radius: 8px;
      }
      position: relative;
      .personage_img_marking {
        top: 0;
        left: 0;
        position: absolute;
        width: 56px;
        height: 32px;
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        line-height: 32px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 8px 0px 8px 0px;
      }
    }
    .personage_ul_main {
      height: 165px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .personage_ul_name {
        color: #333333;
        font-size: 18px;
        font-weight: bold;
      }
      .personage_ul_title {
        color: #999999;
        font-size: 14px;
      }
      .personage_ul_price {
        display: flex;
        align-items: flex-end;
        .personage_ul_price_left {
          display: flex;
          align-items: center;
          span {
            color: #ff2121;
            font-size: 22px;
            font-weight: bold;
          }
        }
        .personage_ul_price_right {
          display: flex;
          align-items: center;
          span {
            color: #999999;
            font-size: 18px;
          }
        }
        img {
          width: 11px;
          height: 14px;
          margin-left: 10px;
        }
      }
      .personage_ul_footer {
        width: 100%;
        align-items: flex-end;
        .personage_ul_footer_phone {
          width: 36px;
          height: 36px;
          background: #ffe7e3;
          border-radius: 50%;
        }
        .personage_ul_footer_img {
          img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
          }
          margin-right: 10px;
        }
        .personage_ul_footer_main {
          height: 48px;
          align-items: flex-start;
          flex-direction: column;
        }
        .personage_ul_footer_name {
          color: #333333;
          font-size: 16px;
          font-weight: bold;
        }
        .personage_ul_footer_time {
          span {
            color: #ff9600;
            font-size: 14px;
            &:first-child {
              margin-right: 40px;
            }
          }
        }
      }
    }
  }
}
</style>
